import { useRouteError } from 'react-router-dom'
// import Navigation from "../Navigation/Navigation";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function ErrorPage() {

    let error = useRouteError();
    console.error("ERROR",error);

    return (
        <>
            <Header></Header>
            {/* <Navigation></Navigation> */}
            <main>
                <h1>An error occurred!</h1>
                <h2>Error Data: {error.data}</h2>
                <h2>Error Status: {error.status}</h2>
                <h2>Error Status Text: {error.statusText}</h2>
                <h2>Error Message: {error.message}</h2>
            </main>
            <Footer></Footer>
        </>
    );
}

export default ErrorPage;