import React from 'react';
import {useRouteLoaderData} from 'react-router-dom';
import SEO from '../SEO/SEO';
import classes from  './photos.module.css';

// const Photos = (props) => {
const Photos = () => {
    const loaderData = useRouteLoaderData("artist-full-data");
    const data = loaderData.artistPhotos;

    // let data = props.photoData;
    // console.log("props.photoData",props.photoData);   
    
    return (
        <>
            <SEO
                canonical="https://dinopacificimusic.com/photos"
                title="Dino Pacifici Music - Photos"
                description="Dino Pacifici Music - Photos"
                keywords="Dino Pacifici Photos, "
                robots="index, follow"
            />

            <div className={"" + classes.photoDivWrapper}>
                <div className= {"row m-0 " + classes.photoContainer}>
                    {data.map((p, index) => (
                        <div key={p.pK_PhotoId} className={"col-xl-4 col-lg-6 col-md-6 col-sm-12 p-0 "}>
                            <div className={classes.photoDiv}>
                                <div className={classes.hidden}>photoId: {p.pK_PhotoId}</div>
                                <div className={classes.hidden}>artistId: {p.fK_ArtistId}</div>
                                <div className={classes.hidden}>photoName: {p.photoName}</div>
                                <div className={classes.hidden}>photoDate: {p.photoDate}</div>
                                <img 
                                    className={classes.img} 
                                    src={"/images/photos/" + p.photoName}
                                    alt={p.photoDescription}
                                    title={p.photoDescription}
                                />
                                <div className={classes.photoTitle}>{p.photoDescription}</div>
                                <div className={classes.photoDescription}>{p.photoStory}</div>
                                <div className={classes.hidden}>photoSequence: {p.photoSequence}</div>
                                <div className={classes.hidden}>photoShow: {String(p.photoShow)}</div>
                            </div>
                        </div>
                    ))}  
                </div>
            </div>
        </>
    )};

export default Photos;