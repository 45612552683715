import { React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faBandcamp, faFacebook, faInstagram, faSquareFontAwesomeStroke } from '@fortawesome/free-brands-svg-icons';




import './SocialMediaNav.css';

const SocialMediaNav = () => {
    return (
        <>
            <div  className="divSocialMediaNav">
                <a className="" href="https://dinopacifici.bandcamp.com/" target='_blank' title="Dino on Bandcamp"><FontAwesomeIcon icon={faBandcamp} /></a>
                <a className="" href="https://www.youtube.com/user/deepacifici" target='_blank' title="Dino on YouTube"><FontAwesomeIcon icon={faYoutube} /></a>
                <a className="" href="https://www.facebook.com/dee.pacifici" target='_blank' title="Dino on Facebook"><FontAwesomeIcon icon={faFacebook} /></a>
                <a className="" href="https://www.instagram.com/dinopacifici/" target='_blank' title="Dino on Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
                <a className="" href="https://dinopacifici.picfair.com/" target='_blank' title="Dino on PicFair"><FontAwesomeIcon icon={faSquareFontAwesomeStroke} /></a>
              </div>
        </>
)};

export default SocialMediaNav;