import { NavLink } from 'react-router-dom';
import classes from './Navigation.module.css';

function Navigation() {
    return (
        <>
            <nav
                className={
                    classes.mainNav +
                    ' flex-shrink navbar navbar-expand-sm navbar-dark'
                }
            >
                <div className="container-fluid">
                    <span className={classes.dpDotCom + ' navbar-brand'}>
                        DinoPacificiMusic.com
                        <br/>
                       <span><a className={classes.dpDotComA + ' '} href="mailto:chill2058@gmail.com">chill2058@gmail.com</a></span>
                    </span>

                    {/* <a className={classes.bandcamp} href="https://dinopacifici.bandcamp.com/" target='_blank' title="Dino on Bandcamp"><FontAwesomeIcon icon={faBandcamp} /></a>
                    <a className={classes.youtube}href="https://www.youtube.com/user/deepacifici" target='_blank' title="Dino on YouTube"><FontAwesomeIcon icon={faYoutube} /></a>
  */}
                    <button
                        className={classes.nBarToggler + ' navbar-toggler'}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsibleNavbar"
                    >
                        <span
                            className={
                                classes.nBarTogglerIcon + ' navbar-toggler-icon'
                            }
                        ></span>
                    </button>
                    <div
                        className={
                            classes.nBarCollapse + ' collapse navbar-collapse'
                        }
                        id="collapsibleNavbar"
                    >
                        <ul
                            className={classes.nBarNav + ' navbar-nav  mx-auto'}
                        >
                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    isActive ? classes.active : undefined
                                }
                                end={true}
                            >
                                Home
                            </NavLink>
                            <NavLink
                                to="/albums"
                                className={({ isActive }) =>
                                    isActive ? classes.active : undefined
                                }
                                end={true}
                            >
                                Albums
                            </NavLink>
                            <NavLink
                                to="/photos"
                                className={({ isActive }) =>
                                    isActive ? classes.active : undefined
                                }
                                end={true}
                            >
                                Photos
                            </NavLink>
                            <NavLink
                                to="/instruments"
                                className={({ isActive }) =>
                                    isActive ? classes.active : undefined
                                }
                                end={true}
                            >
                                Instruments
                            </NavLink>
                            <NavLink
                                to="/biography"
                                className={({ isActive }) =>
                                    isActive ? classes.active : undefined
                                }
                                end={true}
                            >
                                Biography
                            </NavLink>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navigation;