import React from 'react';
import {useRouteLoaderData} from 'react-router-dom';
import SEO from '../SEO/SEO';
import classes from './bio.module.css'

// const Bio = (props) => {
const Bio = (props) => {
    const loaderData = useRouteLoaderData("artist-full-data");
    // console.log("loaderData",loaderData);   

    // const artistId = props.artistData.pK_ArtistId;
    // const artistImg = '/images/site/' + props.artistData.artistImage;
    // const artistName = props.artistData.artistName;
    // const artistBio = props.artistData.artistBio;

    const artistId = loaderData.artist.pK_ArtistId;
    const artistImg = '/images/site/' + loaderData.artist.artistImage;
    const artistName = loaderData.artist.artistName;
    const artistLocation = loaderData.artist.artistLocation;
    const artistBio = loaderData.artist.artistBio;

    return (
        <>
            <SEO
                canonical="https://dinopacificimusic.com/biography"
                title="Dino Pacifici Music - Biography"
                description="Dino Pacifici Music - Biography"
                keywords="Dino Pacifici Biography, "
                robots="index, follow"
            />

            {/* ---- BIO ---- */}
            <div id={classes.artistInfoWrapper} key={artistId}>
                <div id={classes.artistInfoContainer} className="container-fluid ">
                    <div className="row">
                        <div className={"col-lg-12 col-sm-12 " + classes.flexbox} >
                            <img 
                                src={artistImg}
                                className={'rounded-circle flex ' + classes.artistImage}
                                title={artistName}
                                alt={artistName}
                            />
                        </div>
                        <div className="col-lg col-sm-12" >
                            <div className={'row  ' + classes.artistInfo_Name_Location }>
                                <div>
                                    {/* {props.artistData.artistName}{' - '}{props.artistData.artistLocation} */}
                                    {artistName}{' - '}{artistLocation}
                                </div>  
                            </div>
                            <div className={"" + classes.textJustify }>
                                <pre className={"" + classes.artistBio }>
                                    {artistBio}
                                </pre>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </>
    )};

export default Bio;