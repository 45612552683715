import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import RootLayout from './Components/RootLayout/RootLayout';
import AlbumsRootLayout from './Components/AlbumsRootLayout/AlbumsRootLayout';
import ErrorPage from './Components/Error/ErrorPage';
import ErrorPage404 from './Components/Error404/Error404';

import Artist from './Components/Artist/Artist';
import Albums from './Components/Albums/Albums';
import AlbumsDetail from './Components/Albums/AlbumsDetail';
import Photos from './Components/Photos/Photos';
import Instruments from './Components/Instruments/Instruments';
import Bio from './Components/Bio/Bio';

import { fetchStaticData } from '../src/Data/fetchDataStatic';
import { loader } from '../src/Data/GetData';

// //------------------------------------------------------
// // -- if not localhost, remove 'www' and 'http' and redirect to https://
// if(window.location.hostname !== 'localhost') {
//     if (window.location.href.toLowerCase() === "https://www.dinopacificimusic.com/" || window.location.href.toLowerCase() === "https://www.dinopacificimusic.com"){
//         window.location.href = "https://dinopacificimusic.com";
//     }

//     if ((window.location.href.toLowerCase().indexOf('www.') > -1) || (window.location.protocol.toLowerCase() !== 'https:')) {
//             let href = window.location.href;

//             href = href.replace('www.', '');
//             href=href.replace("https://www.","https://")
//             href = href.replace('http:', 'https:');

//             window.location.href = href;
//     }
// };
// //------------------------------------------------------



function App() {
    // console.log(loader.data);

    // const [fetchData, SetFetchData] = useState([]);
    // const [albums, setAlbums] = useState([]);
    // const [photos, setPhotos] = useState([]);
    // const [instruments, setInstruments] = useState([]);
    // const [links, setLinks] = useState([]);
    // const [latestRelease, setLatestRelease] = useState([]);
    // const [artist, setArtist] = useState("Dino Pacifici");
    // const [isDataLoaded, SetIsDataLoaded] = useState(false);

    // useEffect(() => {
    //     //fetchStaticDataFromObject();
    //     //fetchAPIDataHandler();
    //     // console.log("APPJS - USEEFFECT - fetchData",fetchData);
    // }, [isDataLoaded]);

    const router = createBrowserRouter([
        {
            path: '/',
            id: 'artist-full-data',
            // element: <RootLayout linkData={links} />,
            // errorElement: <ErrorPage linkData={links} />,
            element: <RootLayout />,
            loader: loader,
            errorElement: <ErrorPage />,
            children: [
                {
                    index: true,
                    element: (
                        <Artist
                        // artistData={artist}
                        // latestRelease={latestRelease}
                        // isDataLoaded={isDataLoaded}
                        />
                    ),
                    // loader: loader
                }, // path:''
                {
                    path: 'albums',
                    element: <AlbumsRootLayout />,
                    errorElement: <ErrorPage />,
                    children: [
                        // { index: true, element: <Albums albumData={albums} /> },
                        // { path: ':pK_AlbumId', element: <AlbumsDetail albumData={albums} /> },
                        {
                            index: true,
                            element: <Albums />,
                            // loader: loader
                        },
                        {
                            // path: ':pK_AlbumId',
                            path: ':albumFriendlyUrl',
                            // element: <AlbumsDetail />,
                            children: [
                                {
                                    index: true,
                                    element: <AlbumsDetail />,
                                },
                            ],

                            // loader: loader,
                        },
                    ],
                },
                // { path: 'photos', element: <Photos photoData={photos} /> },
                // { path: 'instruments', element: <Instruments instrumentData={instruments}/>},
                // { path: 'biography', element: <Bio artistData={artist}/>},
                {
                    path: 'photos',
                    element: <Photos />,
                    // loader: loader
                },
                {
                    path: 'instruments',
                    element: <Instruments />,
                    // loader: loader,
                },
                {
                    path: 'biography',
                    element: <Bio />,
                    // loader: loader
                },
                {
                    path: '*',
                    element: <ErrorPage404 />,
                    // loader: loader
                },
            ],
        },
    ]);

    // const httpURL = 'http://localhost:5166/api/v1/ArtistAPI/1';
    // //const httpURL = 'https://localhost:443/api/v1/ArtistAPI/1';
    // // https://localhost:7030
    // // http://localhost:5166

    function fetchStaticDataFromObject() {
        // console.log(loader);
        // SetIsDataLoaded(true);
        // console.log("FetchStaticData:", {fetchStaticData});
        // setArtist(fetchStaticData[0].artist);
        // setAlbums(fetchStaticData[0].artistAlbums);
        // setLatestRelease(fetchStaticData[0].artistAlbums[0]);
        // setPhotos(fetchStaticData[0].artistPhotos);
        // setInstruments(fetchStaticData[0].artistInstruments);
        // setLinks(fetchStaticData[0].artistLinks);
        // //loader
        // SetIsDataLoaded(true);
        // // console.log("FetchStaticData:", {fetchStaticData});
        // setArtist(loader[0].artist);
        // setAlbums(loader[0].artistAlbums);
        // setLatestRelease(loader[0].artistAlbums[0]);
        // setPhotos(loader[0].artistPhotos);
        // setInstruments(loader[0].artistInstruments);
        // setLinks(loader[0].artistLinks);
    }

    // function fetchAPIDataHandler() {
    //     // console.log('fetching');
    //     var headers = {
    //         // 'Access-Control-Request-Private-Network': 'true',
    //         // 'Access-Control-Allow-Private-Network': 'true',
    //         // 'My-Name-Is': 'Julien-Dumont',
    //     };

    //     fetch(httpURL, {
    //         method: 'GET',
    //         mode: 'cors',
    //         headers: headers,
    //     })
    //         .then((response) => {
    //             //console.log("RESPONSE:");
    //             //console.log(response);
    //             return response.json();
    //         })
    //         .then((data) => {
    //             // console.log('app.js fetch data');
    //             // console.log(data);
    //             SetIsDataLoaded(true);
    //             SetFetchData(data);
    //             console.log("SetFetchData",fetchData);
    //             setArtist(data.result.artist);
    //             // console.log(data.result.artist);
    //             setAlbums(data.result.artistAlbums);
    //             //console.log(data.result.artistAlbums);
    //             setLatestRelease(data.result.artistAlbums[0]);
    //             //console.log(data.result.artistAlbums[0]);
    //             setPhotos(data.result.artistPhotos);
    //             //console.log(data.result.artistPhotos);
    //             setInstruments(data.result.artistInstruments);
    //             //console.log(data.result.artistInstruments);
    //             setLinks(data.result.artistLinks);
    //             //console.log(data.result.artistLinks);

    //             //setIsloaded(true);
    //         })
    //         .catch((error) => {
    //             console.log('ERROR:');
    //             console.log(error);
    //             throw error;
    //         });
    // }

    // return { isDataLoaded }
    return {} ? (
        // <HelmetProvider>
        <RouterProvider router={router} />
    ) : // </HelmetProvider>
    null;
}

export default App;
