import { Outlet } from "react-router-dom";

function AlbumsRootLayout(props) {

    let links = props.linkData;

    return (
        <>
            <main>
                <Outlet />
            </main>
        </>
    )
}

export default AlbumsRootLayout;