import { useState, useEffect, useRef, useCallback } from 'react';
import {IoMdVolumeHigh, IoMdVolumeOff, IoMdVolumeLow} from 'react-icons/io';

import nxt from '../../Assets/images/MusicPlayer/nxt.png';
import prv from '../../Assets/images/MusicPlayer/prv.png';

import './AudioPlayer.css';

const Controls = ({ 
                    audioRef, 
                    progressBarRef, 
                    duration, 
                    setTimeProgress,
                    handlePrevious,
                    handleNext,
                    setIsPlaying,
                    isPlaying   
                }) => {
    
    const [volume, setVolume] = useState(100);
    const [muteVolume, setMuteVolume] = useState(false);

    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
    };

    const playAnimationRef = useRef();

    const repeat = useCallback(() => {

        if (audioRef?.current?.currentTime != null) {
            const currentTime = audioRef?.current?.currentTime;
            setTimeProgress(currentTime);
            progressBarRef.current.value = currentTime;
            progressBarRef.current.style.setProperty(
              '--range-progress',
              `${(progressBarRef.current.value / duration) * 100}%`
            );
        }
        else {
        }
      
        playAnimationRef.current = requestAnimationFrame(repeat);
      }, [audioRef, duration, progressBarRef, setTimeProgress]
    );

     useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
        playAnimationRef.current = requestAnimationFrame(repeat)
    }, [isPlaying, audioRef, repeat]);

    useEffect(() => {
        if (audioRef) {
          audioRef.current.volume = volume / 100;
          audioRef.current.muted = muteVolume;
        }
      }, [volume, audioRef, muteVolume]);

    // const skipForward = () => {
    //     audioRef.current.currentTime += 15;
    // };

    // const skipBackward = () => {
    //     audioRef.current.currentTime -= 15;
    // };

     useEffect(() => {
        if (audioRef) {
          audioRef.current.volume = volume / 100;
        }
      }, [volume, audioRef]);

    return(
    <>
        <div className="controlsWrapper">
            <div className="musicControls">
                <button 
                    className="BTN btnBackward"
                    onClick={handlePrevious}
                >
                    <img src={prv} alt=""/>
                </button>
                <button
                    className={`btnPlay ${!isPlaying ? " pause" : ""}`}
                    onClick={togglePlayPause}>
                    <span></span>
                    <span></span>
                </button>
                <button 
                    className="BTN btnForward"
                    onClick={handleNext}
                >
                    <img src={nxt} alt=""/>
                </button>
            </div>

            <div className="volume">
                <button onClick={() => setMuteVolume((prev) => !prev)}>
                    {muteVolume || volume < 5 ? (
                        <IoMdVolumeOff />
                    ) : volume < 40 ? (
                        <IoMdVolumeLow />
                    ) : (
                        <IoMdVolumeHigh />
                    )}
                </button>
                <input 
                    type="range" 
                    min={0} 
                    max={100}
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                    style={{
                        background: `linear-gradient(to right, #d5eebb ${volume}%, #ccc ${volume}%)`,
                    }} 
                />
            </div>
        </div>
    </>);
  };

  export default Controls;