import { React } from 'react';
import Navigation from '../Navigation/Navigation';
import SocialMediaNav from '../SocialMediaNav/SocialMediaNav';
import dpmBanner from '../../Assets/images/site/DinoPacificiMusicBanner.png';

import './Header.css';

const Header = () => {
    return (
        <>
            <div className="">
                <div className="banner-wrapper">
                    <img id="banner-img" src={dpmBanner} alt="Dino Pacifici Music"></img>
                </div>
                <Navigation></Navigation>
                <div className="socialMediaDivWrapper">
                        <SocialMediaNav></SocialMediaNav>
                </div>
            </div>
        </>
)};

export default Header;