import React from 'react';
import {NavLink, useRouteLoaderData} from 'react-router-dom';

import SEO from '../SEO/SEO';

import classes from '../Navigation/Navigation.module.css';
import './Albums.css';

// const Albums = (props) => {
const Albums = () => {
    const loaderData = useRouteLoaderData("artist-full-data");
    // let data = props.albumData;
    let data = loaderData.artistAlbums;
    //console.log(data);

    return (
        <>
            <SEO
                canonical="https://dinopacificimusic.com/albums"
                title="Dino Pacifici Music - Albums"
                description="Dino Pacifici Music - Album Calatlog"
                keywords="Album Catalog, "
                robots="index, follow"
            />

            <div className="albumWrapperDiv">
                {' '}
                {/*--START: outer wrapper*/}
                <div className="container-fluid m-0 g-0 p-0">
                    <div className="albumHolderDiv row justify-content-center p-0 m-0 g-0">
                        {data.map((a, index) => (
                            <div
                                key={a.pK_AlbumId}
                                // className="albumDiv col-lg-2 col-md-3 col-sm-4 flex-column  p-0 m-0 g-0"
                                className="albumDiv col-lg-2 col-md-3 col-sm-4 flex-column  p-0 g-0"
                                title={a.albumTitle}
                            >
                                {/* <Link to={`/albums/${a.pK_AlbumId}`}> */}
                                <NavLink
                                    // to={`${a.pK_AlbumId}`}
                                    to={`${a.albumFriendlyUrl}`}
                                    className={({ isActive }) =>
                                        isActive ? classes.active : undefined
                                    }
                                >
                                    <div>
                                        <div className="albumDivAlbumTitle">
                                            {a.albumTitle}
                                        </div>
                                        <div>
                                            <img
                                                className="img-responsive"
                                                src={
                                                    '\\images\\albums\\' +
                                                    a.albumImageCover
                                                }
                                                alt={a.albumTitle}
                                                title={a.albumTitle}
                                            />
                                        </div>

                                        <div className="albumDivAlbumCopyright">
                                            {a.albumCopyright}
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Albums;