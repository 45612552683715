import { useLocation } from 'react-router-dom'
import img404 from '../../Assets/images/site/Page404.jpg'
import './Error404.css';

function ErrorPage404() {
    const loc = useLocation();
   
    return (
        <>
            <main>
                <div className="errorDiv">
                    <h1>No surfing here...</h1>
                    <h2>The page you are looking for was not found!</h2>
                    <img className="errorImg" src={img404} alt="Error"/>    
                    <div className="errorPath">We are sorry, but path "<h3>{loc.pathname}</h3>" was not found.</div>  
                </div>  
            </main>
        </>
    );
}

export default ErrorPage404;