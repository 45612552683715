import { fetchStaticData } from '../Data/fetchDataStatic';

export async function loader() {
    // ---------------------------------------------------------------------------------------------
    // ----------------------------------------------
    // -- Return Static Data
    console.log("fetchStaticData", fetchStaticData[0])
    return fetchStaticData[0];

 




//     //---------------------------------------------------------------------------------------------
//     // //----------------------------------------------
//     //-- Return API Data
//     //const weather = 'http://localhost:5200/WeatherForecast';
//     //const dinoIIS = 'http://localhost:5166/api/v1/ArtistAPI/1';
//     const dinoDEV = 'http://localhost:6166/api/v1/ArtistAPI/1';
//     //const dinoDEV2 = 'http://localhost:6166/WeatherForecast';

//     //const dino = "https://fantasywarrior.net/api/v1/ArtistAPI/1";

//     const fetchThis = dinoDEV;
    
//     let headers = new Headers();
//     //headers.append('Content-Type', 'application/json');
//    // headers.append('Accept', 'application/json');
//     headers.append('Origin',fetchThis);
    
//     try {
//         const response = await fetch(fetchThis, {
//             // method: 'GET',
//             // mode: 'cors',
//             headers: headers,
//         })

//         if (!response.ok) {
//             return { isError: true, message: 'Count not fetch events.' };
//         } else {
//             const resData = await response.json();
//             console.log('RES-DATA: ', resData);
//             console.log('RES-DATA.RESULT: ', resData.result);
//             return resData.result;
//         }

//         const resp = await response.json();
//         console.log(resp);
//         console.log(resp.result);
//         return resp.result;
//     } catch (err) {
//         throw(err);
//         return null;
//         //alert(err); // Failed to fetch
//     }



    // // //---------------------------------------------------------------------------------------------
    // // //----------------------------------------------
    // //-- Return API Data
    // await fetch('http://localhost:6166/api/v1/ArtistAPI/1')
    //     .then((response) => {
    //         //console.log("RESPONSE:");
    //         //console.log(response);
    //         return response.json();
    //     })
    //     .then((data) => {
    //         console.log('data.result', data.result);
    //         return data.result;
    //     })
    //     .catch((error) => {
    //         console.log('ERROR:');
    //         console.log(error);
    //         throw error;
    //     });
}
