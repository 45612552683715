import { React, useState, useEffect } from 'react';
import classes from './links.module.css';

const Links = (props) => {
    
    let data = props.linkData;

    const [linksPurchase, setLinksPurchase] = useState();
    const [linksPurchase2, setLinksPurchase2] = useState();
    const [linksPersonal, setLinksPersonal] = useState();
    const [linksArticle, setLinksArticle] = useState();

    useEffect(() => {
        if (props.linkData != null) {
            setLinksPurchase(
                data.filter((data) => data.linkCategory == 'Purchase')
            );
            setLinksPurchase2(
                data.filter((data) => data.linkCategory == 'Purchase2')
            );
            setLinksPersonal(
                data.filter((data) => data.linkCategory == 'Personal')
            );
            setLinksArticle(
                data.filter((data) => data.linkCategory == 'Article')
            );
        }
    }, [props.linkData]);

    let purchaseLinks = linksPurchase?.map((l, index) => (
        <li key={l.pK_LinkId}>
            <a href={l.linkUrl} target="_blank">
                {l.linkLabel}
            </a>
        </li>
    ));

    let purchase2Links = linksPurchase2?.map((l, index) => (
        <li key={l.pK_LinkId}>
            <a href={l.linkUrl} target="_blank">
                {l.linkLabel}
            </a>
        </li>
    ));

    let personalLinks = linksPersonal?.map((l, index) => (
        <li key={l.pK_LinkId}>
            <a href={l.linkUrl} target="_blank">
                {l.linkLabel}
            </a>
        </li>
    ));

    let articleLinks = linksArticle?.map((l, index) => (
        <li key={l.pK_LinkId}>
            <a href={l.linkUrl} target="_blank">
                {l.linkLabel}
            </a>
        </li>
    ));

    return (
        <>
            <div id={classes.linkDiv} className="container-fluid p-0">
                <div id={classes.linkDivHolder}>
                    <div className={classes.center + ' row'}>
                        <div
                            className={
                                ' col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'
                            }
                        >
                            <label>Where to Purchase:</label>
                            <ul>{purchaseLinks}</ul>
                        </div>
                        <div
                            className={
                                ' col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'
                            }
                        >
                            <label>Additional Purchasing:</label>
                            <ul>{purchase2Links}</ul>
                        </div>
                        <div
                            className={
                                ' col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'
                            }
                        >
                            <label>Personal Links:</label>
                            <ul>{personalLinks}</ul>
                        </div>
                        <div
                            className={
                                ' col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'
                            }
                        >
                            <label>Internet Articles:</label>
                            <ul>{articleLinks}</ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Links;