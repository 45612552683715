import React from 'react';
import {useRouteLoaderData} from 'react-router-dom';
import SEO from '../SEO/SEO';
import classes from  './instruments.module.css';

// const Instruments = (props) => {
const Instruments = () => {
    const loaderData = useRouteLoaderData("artist-full-data");
    // let data = props.instrumentData;
    let data = loaderData.artistInstruments;

    return (
        <>
            <SEO
                canonical="https://dinopacificimusic.com/instruments"
                title="Dino Pacifici Music - Instruments"
                description="Dino Pacifici Music - Instruments"
                keywords="Dino Pacifici Instruments, "
                robots="index, follow"
            />

            <div className={"" + classes.instrumentDivWrapper}>
                <div className= {"row m-0 " + classes.instrumentContainer}>
                    {data.map((i, index) => (
                        <div key={i.pK_InstrumentId} className={"col-xl-4 col-lg-6 col-md-6 col-sm-12 p-0 "}>
                            <div className={classes.instrumentDiv}>
                                <div>
                                    <div className={classes.hidden}>instrumentId: {i.pK_InstrumentId}</div>
                                    <div className={classes.hidden}>artistId: {i.fK_ArtistId}</div>
                                    <div className={classes.hidden}>instrumentName: {i.instrumentName}</div>
                                    <div className={classes.hidden}>instrumentDate: {i.instrumentDate}</div>
                                    <img 
                                        className={classes.img} 
                                        src={"/images/instruments/" + i.instrumentName}
                                        alt={i.instrumentDescription}
                                        title={i.instrumentDescription}
                                        />
                                    <div className={classes.instrumentTitle}>{i.instrumentDescription}</div>
                                    <div className={classes.instrumentDescription}>{i.instrumentStory}</div>
                                    <div className={classes.hidden}>instrumentSequence: {i.instrumentSequence}</div>
                                    <div className={classes.hidden}>instrumentShow: {String(i.instrumentShow)}</div>
                                </div>
                            </div>
                        </div>
                    ))} 
                </div>
            </div>
        </>
    )};

export default Instruments;