import React from "react";
import { Helmet } from "react-helmet-async";


const SearchEngineOptimization = ({
    canonical,
    title,
    description,
    keywords,
    robots,
    artistName,
    albumName,
    trackName
}) => {
    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{title}</title>
            <meta charSet="utf-8" />
            <meta name="copyright" content="DinoPacifiMusic.com - Copyright © Scorpio Rising Music"/>
            <meta name="subject" content="Dino Pacific - Canadian Musician - Album Releases"/>
            <meta name="author" content="Julien J Dumont Jr, julien.j.dumont.jr.developer@gmail.com"/>
            <meta name="reply-to" content="julien.j.dumont.jr.developer@gmail.com"/>
            <meta name="designer" content="Julien J Dumont Jr"/>
            <meta name="owner" content="Dino Pacifici"/>

            <meta name="description" content={description} />
            <meta name="keywords" content={keywords + "Dino Pacifici, Canadian Music Artist, Canada, New Age, Ambient, Smooth Jazz"} />
            <meta name="googlebot" content={robots}/>
            <meta name="robots" content={robots}/>

            <link rel="canonical" href={canonical}/>
            {/* End standard metadata tags */}

            {/* Facebook & OG tags */}
            <meta name="og:title" content={title} />
            <meta name="og:description" content={description} />
            <meta name="og:url" content={canonical}/>
            <meta name="og:type" content="music" />
            
            {/* {albumName && <meta property="og:audio" content="http://example.com/amazing.mp3"/>}; */}
            {albumName &&  <meta property="og:audio:artist" content={artistName}/>};
            {albumName && <meta property="og:audio:album" content={albumName}/>};
            {albumName && <meta property="og:audio:title" content={trackName}/>};
            {albumName &&  <meta property="og:audio:type" content="application/mp3"/>};

            <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" /> 


            {/* Twitter tags */}
            {/* <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} /> */}
            {/* End Twitter tags */}
        </Helmet>
    );
};

export default SearchEngineOptimization;
