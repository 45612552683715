import { React, useState, useEffect } from 'react';
import { useParams, useRouteLoaderData,useNavigate } from 'react-router-dom';

import SEO from '../SEO/SEO';

import AudioPlayer from '../AudioPlayer/AudioPlayer';

// function AlbumsDetail(props) {
function AlbumsDetail() {
    const loaderData = useRouteLoaderData("artist-full-data");
    const params = useParams();

    // let aID = params.pK_AlbumId;
    let albumFriendlyUrl = params.albumFriendlyUrl;
    // console.log("albumFriendlyUrl",albumFriendlyUrl);

    let data = loaderData.artistAlbums.filter((x) => x.albumFriendlyUrl == albumFriendlyUrl);
    // aID = aID[0].pK_AlbumId;

    // console.log("aID",aID);

    // let data = props.albumData.filter((x) => x.pK_AlbumId == aID);
    // let data = loaderData.artistAlbums.filter((x) => x.pK_AlbumId == aID);
    // console.log("loaderData-albumDetail", loaderData );
    const artistName = loaderData.artist.artistName;
    const albumTitle = data[0]?.albumTitle;
    const albumId = data[0]?.pK_AlbumId;
    const albumGenres= data[0]?.albumGenres;
    const albumCopyright = data[0]?.albumCopyright;
    const albumCopyrightDescription =  data[0]?.albumCopyrightDescription

    const fullCopyrightDesc = "© " + albumCopyright + " " + albumCopyrightDescription
    

    // const navigate = useNavigate();
    const [playAlbum, setPlayAlbum] = useState(data[0]);
   
    useEffect(() => {
        setPlayAlbum(data[0]);
    }, [data, playAlbum]);

    // const goBack = () => {
    //     navigate(-1);
    // };

    return (
        <>
            <SEO
                canonical={"https://dinopacificimusic.com/albums/" + albumFriendlyUrl}
                title={"Dino Pacifici Music - " + albumTitle}
                description={"Dino Pacifici Music - " + albumTitle + " - " + fullCopyrightDesc}
                keywords={albumTitle + ", " + albumGenres + ", " + fullCopyrightDesc + ", "} 
                robots="index, follow"
                artistName={artistName}
                albumName={albumTitle}
            />

             <AudioPlayer {...{ playAlbum }} />

            {/* <button onClick={goBack}>Back</button>	 */}
        </>
    );
}

export default AlbumsDetail;