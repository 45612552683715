import './AudioPlayer.css';

const ProgressBar = ({ progressBarRef, audioRef, timeProgress, duration }) => {
    const handleProgressChange = () => {
        audioRef.current.currentTime = progressBarRef.current.value;
    };

    const formatTime = (time) => {
        if (time && !isNaN(time)) {
            const hours = Math.floor(time / 3600);
            const formatHours = hours > 0 ? `${hours}:` : ``;

            const minutes = Math.floor((time / 60) % 60);
            const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

            const seconds = Math.floor(time % 60);
            const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

            return `${formatHours}${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };

    return (
        <>
            <div className="sliderWrapper">
                <div className="songSlider">
                    <input
                        className="songSliderSeek"
                        type="range"
                        ref={progressBarRef}
                        defaultValue="0"
                        onChange={handleProgressChange}
                    />
                    <div className="timeHolder">
                        <span className="songCurrentTime">
                            {formatTime(timeProgress)}
                        </span>
                        <span className="songDuration">
                            {formatTime(duration)}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;
