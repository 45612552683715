import { Outlet,useLoaderData } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

// function RootLayout(props) {
function RootLayout() {
    const loaderData = useLoaderData();
    // let links = props.linkData;
    let links = loaderData.artistLinks;

    return (
        <>
            <Header></Header>
            <main>
                <Outlet />
            </main>
            <Footer linkData={links}></Footer>
        </>
    );
}

export default RootLayout;