import { React, useState } from 'react';
import {useRouteLoaderData} from 'react-router-dom';
import SEO from '../SEO/SEO';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import classes from './artist.module.css';

// const Artist = ({ artistData, latestRelease, isDataLoaded }) => {
const Artist = () => {
    
    const loaderData = useRouteLoaderData("artist-full-data");
    //  console.log("loaderData",loaderData);   
    // console.log("loaderData",loaderData.artistAlbums[0]);
    const artistData = loaderData.artist;
    // const latestRelease=loaderData.artistAlbums[0];
    // const latestRelease=loaderData.artistAlbums[1];
    const featuredAlbum = loaderData.artistFeaturedAlbum;
    //console.log("featuredAlbum: ",featuredAlbum);
    const isDataLoaded=true;

    return (
        <>
            <SEO
                canonical="https://dinopacificimusic.com"
                title="Dino Pacifici Music - Home Page"
                description="Dino Pacifici Music - Website Home Page"
                keywords="Latest Release, "
                robots="index, follow"
            />

            <div className={classes.homeDivWrapper}>
                <div className={classes.homeContainer}>
                    <div className={classes.artistLatestReleaseInfomationDiv}>
                        <h1>{artistData.artistLatestReleaseInformation}</h1>
                    </div>
                </div>
            </div>

            {isDataLoaded ? <AudioPlayer playAlbum={featuredAlbum} /> : ''}
        </>
    );
};

export default Artist;
