import defaultAlbumImage from '../../Assets/images/MusicPlayer/album.png';
import './AudioPlayer.css';

const DisplayTrack = ({
    audioRef,
    setDuration,
    progressBarRef,
    handleNext,
    currentAlbum,
    currentAlbumTrack,
    currentArtistName,
    isPlaying,
}) => {
    
    const onLoadedMetadata = () => {
        const seconds = audioRef.current.duration;
        setDuration(seconds);
        progressBarRef.current.max = seconds;
    };

    const albumImage = currentAlbum?.albumImageCover;

    return (
        <>
            <audio
                src={'/mp3/' + currentAlbumTrack?.trackMP3}
                ref={audioRef}
                onLoadedMetadata={onLoadedMetadata}
                onEnded={handleNext}
            />
            <div className="artistName" title={'Artist: ' + currentArtistName}>
                {currentArtistName}
            </div>
            <div
                className="albumTitle"
                title={'Album: ' + currentAlbum?.albumTitle}
            >
                {currentAlbum?.albumTitle}
            </div>
            <div
                className={`albumArt ${
                    isPlaying ? ' play addHole albumArtRound' : ''
                }`}
            >
                {albumImage ? (
                    <img
                        className={`${isPlaying ? 'albumArtRound' : ''}`}
                        src={
                            '/images/albums/' + currentAlbum.albumImageCover
                        }
                        alt={currentAlbum.albumTitle}
                    />
                ) : (
                    <img src={defaultAlbumImage} alt="album" />
                )}
            </div>
            <div
                className="musicTitle"
                title={'Track: ' + currentAlbumTrack?.trackTitle}
            >
                {currentAlbumTrack?.trackTitle}
            </div>
        </>
    );
};

export default DisplayTrack;