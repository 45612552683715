import { React } from 'react';
import Links from '../Links/Links';
import classes from './footer.module.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const Footer = (props) => {
    
  let links = props.linkData;
    let currentYear = new Date().getFullYear();

    function getMyToolTipFunction() {
        alert('yo');
        var popup = document.getElementById('displayText');
        popup.classList.toggle('show');
    }

    return (
        <>
            <div className={classes.footerDiv}>
                {<Links linkData={links}></Links>}
            </div>

            <div className={classes.copyright} id="clickable">
                Copyright © 2023-{currentYear} Dino Pacifici Music
            </div>
            <ReactTooltip
                className={classes.copyright + ' ' + classes.tooltip}
                anchorSelect="#clickable"
                clickable
            >
                <a href="http://www.juliendumont.com" target="_blank">
                    <button>
                        Developed by: Julien J. Dumont Jr.
                        <br />
                        Technologies: React, JavaScript, Bootstrap, EF Core, SQL
                        Server.
                        <br />
                        (August-November 2023)
                    </button>
                </a>
            </ReactTooltip>
        </>
    );
};

export default Footer;